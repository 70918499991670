import { chain, trimEnd } from 'lodash';
import { readableUrl, urijs } from '@wix/communities-blog-client-common';

export const getRawOuterUrl = (path, sectionUrl = '') => {
  const url = new urijs(sectionUrl).unicode();
  const location = new urijs(path).unicode();
  const pathname = trimEnd(readableUrl(url.pathname()), '/') + location.pathname();
  // addQuery breaks for encoded characters
  const query = chain([url.query(), location.query()])
    .compact()
    .join('&')
    .value();
  url.pathname(pathname);
  url.query(query);
  url.hash(location.hash() || url.hash());
  return url;
};

const getOuterUrl = (path, sectionUrl) => readableUrl(getRawOuterUrl(path, sectionUrl));

export default getOuterUrl;
