import { get } from 'lodash';
import {
  VIEW_MODE_SITE,
  VIEW_MODE_EDITOR,
  VIEW_MODE_PREVIEW,
  VIEW_MODE_ONBOARDING,
} from '@wix/communities-blog-client-common';

export const getBasicParams = state => state.basicParams || {};

export const getViewMode = state => getBasicParams(state).viewMode;
export const getFormFactor = state => getBasicParams(state).formFactor;
export const getInstance = state => getBasicParams(state).instance;
export const getLanguage = state => getBasicParams(state).language || 'en';
export const getUrl = state => getBasicParams(state).url;
export const isSSR = state => getBasicParams(state).isSSR;
export const getIsRTL = state => getBasicParams(state).isRTL;
export const getApiBaseUrl = state => getBasicParams(state).apiBaseUrl;
export const isDebug = state => getBasicParams(state).isDebug;
export const isProduction = state => getBasicParams(state).isProduction;

export const isSite = state => getViewMode(state) === VIEW_MODE_SITE;
export const isEditor = state => getViewMode(state) === VIEW_MODE_EDITOR;
export const isPreview = state => getViewMode(state) === VIEW_MODE_PREVIEW;
export const isAdi = state => getViewMode(state) === VIEW_MODE_ONBOARDING;
export const isSeo = state => getBasicParams(state).isSeo;
export const getIsMobile = state => getFormFactor(state) === 'Mobile';
export const getIsDesktop = state => getFormFactor(state) !== 'Mobile';
export const getIsForumLoadedOnInitialPage = state => {
  const biPageNumber = getBasicParams(state).biPageNumber;
  return biPageNumber !== undefined && biPageNumber === 1;
};

export const getCurrentSitePage = state => get(getBasicParams(state), 'currentPage');
export const getCurrentSitePageTitle = state => get(getCurrentSitePage(state), 'name', '');
